export const myMixins={
    data() {
        return {
          showActive:false,
          a:0
        };
      },
          // inject: ["reload"],
      methods: {
           reload() {
        this.a++
         if(this.a%2==0){
           this.showActive = false
           return
         }
         this.showActive = true
        },
        install() {
          //判断是否是微信浏览器或手机qq浏览器打开
          var u = navigator.userAgent
          let ua = navigator.userAgent.toLowerCase();
       var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
        var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          const isIosQQ = isIOS && / QQ/i.test(navigator.userAgent);
          const isAndroidQQ =
            isAndroid &&
            /MQQBrowser/i.test(navigator.userAgent) &&
            /QQ/i.test(navigator.userAgent.split("MQQBrowser"));
          if (ua.match(/MicroMessenger/i) == "micromessenger") {
               this.reload();
             
          } else if (isIosQQ || isAndroidQQ) {
               this.reload();
        
          } else {
            let testForm = document.createElement("form");
            testForm.setAttribute(
              "action",
              "https://game-img.atoshi.mobi/atoshiwallet/atoshiwallet.apk"
            );
            document.body.append(testForm);
            //提交表单，实现下载
            testForm.submit();
          
          }
         
        },
          anyesc(){
          this.a = 0
            this.showActive = false
      
        },
        toEn(){
          this.$router.push({
            path:"/"
          })
    
        },
        toChina(){
            this.$router.push({
              name:"home"
            })
          }
      },
}