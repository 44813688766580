<template>
  <div class="box"  :class="{active: showActive}" @click="anyesc">
 
      <img class="logo1" src="../../assets/day1_slices/logo@2x.png" alt="" />
      <div class="name"></div>
      <div class="pc-left-white"></div>
      <img
        class="iphone-left"
        src="../../assets/day1_slices/组 7@2x.png"
        alt=""
      />
      <img
        class="iphone-right"
        src="../../assets/day1_slices/组 8@2x.png"
        alt=""
      />
      <img
        class="small-right"
        src="../../assets/day1_slices/球5@2x.png"
        alt=""
      />
      <img class="big-right" src="../../assets/day1_slices/球2@2x.png" alt="" />
      <div class="blue-left"></div>
      <div class="out-install">
        <div class="install" @click.stop="install">
          <span class="bag">Android</span>
        </div>
        <div class="scan-code">
          <span class="bag">Scan to Download</span>
        </div>
      </div>

      <div class="atomic-link">
        <p class="title">Atoshi Wallet</p>
        <p class="desc">Link ATOSHI Ecosystem</p>
      </div>
      <div class="thread2">
        <div class="end-blue-right"></div>
      </div>
      <!-- 中英文切换 -->
      <div class="chinese-english" >
        <div class="english" id="englishqiu">
          EN
        </div>
        <div class="chinese" @click="toChina">
          简体中文
        </div>
        
      
      </div>
            <!-- 移动端中英文切换 -->
      <div class="middle-chinese-english" @click="toChina">
        切换成中文
      </div>
  </div>
</template>

<script>
import { myMixins } from "../mixins";
export default {
  name: "HomeEn",
    mixins: [myMixins],
};
</script>

<style scoped  lang="less">
@import url('../public.less');
 @media screen and(min-width:750px){
.scan-code{
width: 185Px !important;
}
#englishqiu{
        &::after{
               width: 2px;
              height: 2px;
              background: #ffffff;
              border-radius: 50%;
              position: absolute;
              top: 10px;
              right: 0;
              display: block;
              content: '';
              left: 0;
              margin: auto;
             
            
            }
}
.chinese::after{
  display: none !important;
}
 }

</style>